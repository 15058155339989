define('ember-cli-barcode/components/bar-code', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    thisId: null,

    // jsbarcode options
    // https://github.com/lindell/JsBarcode/wiki/Options#format

    defaults: Ember.computed(function () {
      return {
        format: this.get('format') || "CODE128",
        mod43: this.get('mod43') || false, // only used with code39 barcodes
        width: this.get('width') || 2,
        height: this.get('height') || 100,
        displayValue: Ember.isBlank(this.get('displayValue')) ? true : this.get('displayValue'),
        fontOptions: this.get('fontOptions') || "",
        font: this.get('font') || "monospace",
        textAlign: this.get('textAlign') || "center",
        textPosition: this.get('textPosition') || "bottom",
        textMargin: this.get('textMargin') || 2,
        fontSize: this.get('fontSize') || 20,
        background: this.get('background') || "#ffffff",
        lineColor: this.get('lineColor') || "#000000",
        margin: Ember.isBlank(this.get('margin')) ? 10 : this.get('margin'),
        marginTop: Ember.isBlank(this.get('marginTop')) ? undefined : this.get('marginTop'),
        marginBottom: Ember.isBlank(this.get('marginBottom')) ? undefined : this.get('marginBottom'),
        marginLeft: Ember.isBlank(this.get('marginLeft')) ? undefined : this.get('marginLeft'),
        marginRight: Ember.isBlank(this.get('marginRight')) ? undefined : this.get('marginRight'),
        flat: this.get('flat') || false,
        lastChar: this.get('lastChar') || ''
      };
    }),

    didInsertElement: function didInsertElement() {
      this.set('thisId', this.elementId);
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      // if options object is passed in, use it
      var options = this.get('options') || this.get('defaults');

      // set the call back on options
      options['valid'] = function (status) {
        return _this.valid && _this.valid(status);
      };

      // now render the barcode
      JsBarcode('#' + this.get('thisId'), Ember.get(this, 'value'), options);
    }
  });
});