define('ember-modal-service/models/modal', ['exports', 'ember'], function (exports, _ember) {
	var computed = _ember['default'].computed;
	var isBlank = _ember['default'].isBlank;
	var dasherize = _ember['default'].String.dasherize;
	var defer = _ember['default'].RSVP.defer;

	/**
  * Modal object to instantiate modals over controller.
  *
  * @extends Ember.Object
  */
	exports['default'] = _ember['default'].Object.extend({

		/**
   * Name of the modal.
   *
   * @property name
   * @type String
   */
		name: null,

		/**
   * Options of the modal.
   *
   * @property options
   * @type Object
   */
		options: null,

		/**
   * Deferred object.
   *
   * @property deferred
   * @type Object
   */
		deferred: null,

		/**
   * Promise object from deferred.
   *
   * @property promise
   * @type Promise
   */
		promise: computed.reads('deferred.promise'),

		/**
   * Full name for building controllers and views.
   *
   * @property fullname
   * @type String
   */
		fullname: computed('name', function () {
			var name = this.get('name');

			if (isBlank(name)) {
				throw new Error('Modal must have a name.');
			}

			return 'modal-' + dasherize(name);
		}),

		/**
   * Setup the promise on init.
   *
   * @method init
   */
		init: function init() {
			this._super.apply(this, arguments);

			this.set('deferred', defer('Modal: open \'' + this.get('fullname') + '\''));
		}

	});
});