define('ember-modal-service/utils/css-transitions/has-transitions', ['exports'], function (exports) {
  exports['default'] = hasTransitions;
  /**
   * Returns whether an element has CSS transitions applied directly on itself.
   *
   * @method hasTransitions
   * @param {Element} element
   * @return Boolean
   */

  function hasTransitions(element) {
    var _window$getComputedStyle = window.getComputedStyle(element);

    var transitionProperty = _window$getComputedStyle.transitionProperty;
    var transitionDuration = _window$getComputedStyle.transitionDuration;

    var hasNoDurations = transitionDuration.split(', ').every(function (duration) {
      return duration === '0s';
    });

    return !(hasNoDurations || transitionProperty === 'all' && transitionDuration === '0s');
  }
});