define('ember-task-scheduler/services/scheduler', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var FPS = 60;
	var MILLISECONDS = 1000;
	var Service = Ember.Service,
	    run = Ember.run,
	    assert = Ember.assert,
	    computed = Ember.computed,
	    warn = Ember.warn,
	    onerror = Ember.onerror,
	    getOwner = Ember.getOwner;
	var _window = window,
	    requestAnimationFrame = _window.requestAnimationFrame,
	    cancelAnimationFrame = _window.cancelAnimationFrame,
	    performance = _window.performance;


	/**
  * Bind context to method and call requestAnimationFrame with generated function.
  *
  * @method scheduleFrame
  * @param {Object} context
  * @param {String} method
  * @return Number
  * @private
  */
	function scheduleFrame(context, method) {
		method = context[method];

		return requestAnimationFrame(method.bind(context));
	}

	/**
  * Try to exec a function with target and args.
  *
  * When function throws an error it calls onError function with error and stack.
  *
  * @method exec
  * @param {Object} target
  * @param {Function|String} method
  * @param {Array} args
  * @param {Function} onError
  * @param {Error} stack
  * @private
  */
	function exec(target, method, args, onError, stack) {
		try {
			method.apply(target, args);
		} catch (e) {
			if (onError) {
				onError(e, stack);
			}
		}
	}

	/**
  * Service that schedules tasks into browser frames within a given FPS rate.
  *
  * When there are several tasks that fits into the same frame, it tries to execute them.
  * Otherwise, when there are heavy tasks, it tries to spare them into several frames.
  *
  * Methods:
  *  * schedule: add a task into the scheduler.
  *  * scheduleOnce: add a unique task into the scheduler.
  *  * cancel [Array]: array with deleted tasks. Format of each item: [target, method, args].
  *  * hasPendingTasks [Boolean]: return true when there are pending tasks.
  *
  * @namespace App
  * @class SchedulerService
  * @extends Ember.Service
  * @public
  */
	exports.default = Service.extend({

		/**
   * Proxy to app environment configuration.
   *
   * @property config
   * @type Object
   */
		config: computed(function () {
			return getOwner(this).resolveRegistration('config:environment');
		}).readOnly(),

		/**
   * Setup number of frames per second.
   *
   * @property FPS
   * @type Number
   * @public
   */
		FPS: computed.reads('config.taskScheduler.FPS'),

		/**
   * On error hook executed when a task fails.
   *
   * @method onError
   * @param {Error} e
   * @public
   */
		onError: onerror,

		/**
   * Array of tasks of the instance.
   *
   * @property _tasks
   * @type Array
   * @private
   */
		_tasks: null,

		/**
   * ID of the current frame.
   *
   * @property _currentInstance
   * @type Number
   * @private
   */
		_currentInstance: null,

		/**
   * Computed value of milliseconds per frame of current FPS configuration.
   *
   * @property millisecondsPerFrame
   * @type Float
   * @public
   */
		millisecondsPerFrame: computed('FPS', function () {
			var fps = this.get('FPS') || FPS;

			return 1 / fps * MILLISECONDS;
		}),

		/**
   * Initializes array of tasks.
   *
   * @method init
   */
		init: function init() {
			this._super.apply(this, arguments);

			this.set('_tasks', []);
		},


		/**
   * Return when has pending tasks.
   *
   * @method hasPendingTasks
   * @returns Boolean
   * @public
   */
		hasPendingTasks: function hasPendingTasks() {
			return this.get('_tasks.length') !== 0;
		},


		/**
   * Schedules a task into the scheduler.
   *
   * When first argument is a function it ignores the rest.
   *
   * @method schedule
   * @param {Object} target
   * @param {Function|String} method
   * @param {...Mixed} args
   * @public
   */
		schedule: function schedule() {
			var tasks = this.get('_tasks');
			var currentInstance = this.get('_currentInstance');

			tasks.push(this._sliceArguments.apply(this, arguments));

			if (!currentInstance) {
				this._begin();
			}
		},


		/**
   * Schedule a unique task into the scheduler.
   *
   * When first argument is a function it ignores the rest.
   *
   * @method scheduleOnce
   * @param {Object} target
   * @param {Function|String} method
   * @param {...Mixed} args
   * @public
   */
		scheduleOnce: function scheduleOnce() {
			var currentInstance = this.get('_currentInstance');

			this._pushUnique(this._sliceArguments.apply(this, arguments));

			if (!currentInstance) {
				this._begin();
			}
		},


		/**
   * Try to cancel a given task.
   *
   * When first argument is a function it ignores the rest.
   *
   * @method cancel
   * @param {Object} target
   * @param {Function|String} method
   * @returns Array
   * @public
   */
		cancel: function cancel() {
			var currentInstance = this.get('_currentInstance');

			var _sliceArguments = this._sliceArguments.apply(this, arguments),
			    _sliceArguments2 = _slicedToArray(_sliceArguments, 2),
			    target = _sliceArguments2[0],
			    method = _sliceArguments2[1];

			var tasks = this.get('_tasks');
			var removedTasks = [];
			var removedIndexes = [];

			// Find removable tasks.
			for (var i = 0; i < tasks.length; i++) {
				var _tasks$i = _slicedToArray(tasks[i], 2),
				    currentTarget = _tasks$i[0],
				    currentMethod = _tasks$i[1];

				if (currentTarget === target && currentMethod === method) {
					removedIndexes.push(i);
				}
			}

			// Remove tasks.
			for (var _i = 0; _i < removedIndexes.length; _i++) {
				var index = removedIndexes[_i];

				removedTasks.push(tasks.splice(index, 1));
			}

			if (currentInstance && tasks.length === 0) {
				this._end();
			}

			return removedTasks;
		},


		/**
   * Push unique task into scheduler.
   *
   * When a duplicate is found, replace old arguments with new one.
   *
   * @method _pushUnique
   * @param {Array} params
   * @private
   */
		_pushUnique: function _pushUnique(params) {
			var _params = _slicedToArray(params, 4),
			    target = _params[0],
			    method = _params[1],
			    args = _params[2],
			    stack = _params[3];

			var tasks = this.get('_tasks');

			for (var i = 0; i < tasks.length; i++) {
				var _tasks$i2 = _slicedToArray(tasks[i], 2),
				    currentTarget = _tasks$i2[0],
				    currentMethod = _tasks$i2[1];

				if (currentTarget === target && currentMethod === method) {
					tasks[i][2] = args; // replace args
					tasks[i][3] = stack; // eslint-disable-line no-magic-numbers
					return;
				}
			}

			tasks.push(params);
		},


		/**
   * Begin a new frame scheduling loop.
   *
   * @method _begin
   * @private
   */
		_begin: function _begin() {
			assert('Could not schedule a new frame. Scheduler instance is already started', !this.get('_currentInstance'));

			this.set('_currentInstance', scheduleFrame(this, '_loop'));
		},


		/**
   * Schedule a new frame loop.
   *
   * @method _next
   * @private
   */
		_next: function _next() {
			assert('Could not schedule next frame. Scheduler instance is not running', this.get('_currentInstance'));
			assert('Could not schedule next frame. Scheduler has no tasks', this.hasPendingTasks());

			this.set('_currentInstance', scheduleFrame(this, '_loop'));
		},


		/**
   * End current frame loop.
   *
   * If frame loop is not started, it will be canceled.
   *
   * @method _end
   * @private
   */
		_end: function _end() {
			var currentInstance = this.get('_currentInstance');

			assert('Could not stop scheduler. Service instance is not running', currentInstance);

			cancelAnimationFrame(currentInstance);

			this.set('_currentInstance', null);
		},


		/**
   * Frame running loop. It tries to fit tasks in a given frame until frame takes too long.
   *
   * @method _loop
   * @param {Float} startTime
   * @private
   */
		_loop: function _loop(startTime) {
			if (this.isDestroyed) {
				return;
			}

			var millisecondsPerFrame = this.get('millisecondsPerFrame');
			var tasks = this.get('_tasks');
			var target = void 0,
			    method = void 0,
			    args = void 0,
			    stack = void 0;

			assert('Could not run current loop. Service instance has no tasks.', tasks.length !== 0);

			do {
				var _tasks$shift = tasks.shift();

				var _tasks$shift2 = _slicedToArray(_tasks$shift, 4);

				target = _tasks$shift2[0];
				method = _tasks$shift2[1];
				args = _tasks$shift2[2];
				stack = _tasks$shift2[3];


				this._exec(target, method, args, stack);
			} while (!this.isDestroyed && tasks.length > 0 && performance.now() - startTime < millisecondsPerFrame);

			// After exec, service could be destroyed. Recheck.
			if (this.isDestroyed) {
				return;
			}

			if (tasks.length > 0) {
				this._next();
				return;
			}

			var currentInstance = this.get('_currentInstance');

			if (currentInstance) {
				this._end();
			}
		},


		/**
   * Execute function inside ember run loop.
   *
   * @method _exec
   * @param {Object} target
   * @param {Function|String} method
   * @param {Mixed} args
   * @param {Error} stack
   * @private
   */
		_exec: function _exec(target, method, args, stack) {
			var env = this.get('config.environment');
			var millisecondsPerFrame = this.get('millisecondsPerFrame');
			var onError = this.get('onError');
			var startTime = void 0;

			if (env === 'development') {
				startTime = performance.now();
			}

			run.begin();

			exec(target, method, args, onError, stack);

			run.end();

			if (env === 'development') {
				var diff = performance.now() - startTime;

				warn('Scheduled callback took too long (' + diff + ' ms)\n' + stack.stack, diff < millisecondsPerFrame, { id: 'ember-task-scheduler.services.callback-took-too-long' });
			}
		},


		/**
   * Parse arguments and try to extract target, method and args.
   *
   * When first argument is a function ignore the rest and set target to null.
   *
   * @method _sliceArguments
   * @param {Object} target
   * @param {Function|String} method
   * @param {...Mixed} args
   * @private
   */
		_sliceArguments: function _sliceArguments(target, method) {
			for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
				args[_key - 2] = arguments[_key];
			}

			var env = this.get('config.environment');
			var length = arguments.length;

			if (length === 1) {
				method = target;
				target = null;
			}

			if (typeof method === 'string') {
				method = target[method];
			}

			var stack = env === 'development' ? new Error() : null;

			assert('Could not find a valid method to call', method && typeof method === 'function');

			return [target, method, args, stack];
		}
	});
});