/**
 * Check SessionStorage supported
 * @returns {boolean}
 */
function isSessionStorageSupported() {
  var result = true, testKey = 'testSessionKey', storage = window.sessionStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
    } catch (error) {
        result =  false;
    }
    return result;
}

/**
 * set SessionStorage Record
 * @param key
 * @param value
 */
function setSessionStorage(key, value){
    if(isSessionStorageSupported()) {
      var storage = window.sessionStorage;
        storage.setItem(key, value);
    }
}

/**
 * get SessionStorage Record
 * @param key
 * @returns mixed
 */
function getSessionStorage(key){
  var result = undefined, storage = window.sessionStorage;

    if(isSessionStorageSupported()) {
        result = storage.getItem(key);
    }
    return result;
}
