define('ember-modal-service/components/modal-container', ['exports', 'ember', 'ember-modal-service/templates/components/modal-container'], function (exports, _ember, _emberModalServiceTemplatesComponentsModalContainer) {
	var Component = _ember['default'].Component;
	var notEmpty = _ember['default'].computed.notEmpty;
	var service = _ember['default'].inject.service;

	/**
  * Component that acts like a container to wrap modal object on its own component and view.
  *
  * @extends Ember.Component
  */
	exports['default'] = Component.extend({

		layout: _emberModalServiceTemplatesComponentsModalContainer['default'],

		/**
   * Binded CSS classes.
   *
   * @property classNameBindings
   * @type Array
   */
		classNameBindings: ['full-height'],

		/**
   * Show set 100% height.
   *
   * @property fullHeight
   * @type Boolean
   */
		fullHeight: notEmpty('modal.content'),

		/**
   * Modal service injection.
   *
   * @property modal
   * @type Object
   */
		modal: service('modal')

	});
});