define('ember-modal-service/utils/css-transitions/on-transition-end', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = onTransitionEnd;
	var run = _ember['default'].run;

	var eventNames = {
		transition: 'transitionend',
		MozTransition: 'transitionend',
		OTransition: 'oTransitionEnd',
		WebkitTransition: 'webkitTransitionEnd',
		msTransition: 'MSTransitionEnd'
	};

	/**
  * Find transition-end event name on current browser.
  *
  * @method findTransitionEventName
  * @return Boolean
  * @private
  */
	function findTransitionEventName() {
		var div = document.createElement('div');
		var key = Object.keys(eventNames).find(function (eventName) {
			return eventName in div.style;
		});

		return eventNames[key];
	}

	var transitionEndEventName = findTransitionEventName();

	/**
  * Subscribes a callback to a transition-end event by transition property on a given element.
  *
  * @method onTransitionEnd
  * @param {Element} element
  * @param {Function} callback
  * @param {String} transitionProperty
  * @param {Boolean} once
  */

	function onTransitionEnd(element, callback) {
		var transitionProperty = arguments.length <= 2 || arguments[2] === undefined ? 'all' : arguments[2];
		var once = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

		var fn = function fn(e) {
			var propertyName = e.propertyName;
			var type = e.type;

			if (transitionProperty !== 'all' && propertyName !== transitionProperty) {
				return;
			}

			if (once) {
				element.removeEventListener(type, fn, true);
			}

			run(null, callback, e);
		};

		element.addEventListener(transitionEndEventName, fn, true);
	}
});