/**
 * Check LocalStorage supported
 * @returns {boolean}
 */
function isLocalStorageSupported() {
  var result = true, testKey = 'testKeySupported', storage = window.localStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
    } catch (error) {
        result =  false;
    }
    return result;
}

/**
 * Set LocalStorage Record
 * @param key
 * @param value
 */
function setLocalStorage(key, value){
    if(isLocalStorageSupported()) {
      var storage = window.localStorage;
        storage.setItem(key, value);
    }
}

/**
 * Remove LocalStorage Record
 * @param key
 */
function removeLocalStorage(key){
  if(isLocalStorageSupported()) {
    var storage = window.localStorage;
    storage.removeItem(key);
  }
}

/**
 * setLocalStorage
 * @param key
 * @returns mixed
 */

function getLocalStorage(key){
  var result = undefined, storage = window.localStorage;

    if(isLocalStorageSupported()) {
        result = storage.getItem(key);
    }
    return result;
}
